import React from "react"
import SEO from "../components/utils/SEO"
import Layout from "../components/layout/Layout"
import LayoutWithImage from "../components/layout/LayoutWithImage"

const ZeitungMittendrinPage = () => (
  <Layout>
    <SEO title="Zeitung mittendrin" />

    <div className="pt-5 header">
      <h1>Zeitung mittendrin</h1>
      <h2>Menschen mit und ohne Behinderung gestalten zusammen eine Zeitung.</h2>
    </div>

    <LayoutWithImage>
      <p>
        Das Redaktionsteam <i>mittendrin</i> besteht aus Menschen mit und ohne
        Behinderung.
      </p>
      <p>Seit Dezember 2012 gestaltet das Redaktionsteam eine Zeitung.</p>
      <p>Die Zeitung erscheint zwei Mal im Jahr.</p>
      <p>Alle Haushalte in Liechtenstein bekommen die Zeitung.</p>
      <p>
        Die Zeitung macht auf Probleme von Menschen mit besonderen Bedürfnissen
        aufmerksam.
      </p>
      <p>Sie informiert über Behinderungsarten.</p>
      <p>Sie informiert über Recht, Gesetz und Allgemeines.</p>
      <p>Menschen mit einer Behinderung können erzählen, wie es ihnen geht.</p>
      <p>
        Menschen mit einer Behinderung können erzählen, wie sie die Gesellschaft
        sehen.
      </p>
      <p>
        Alle Ausgaben der Zeitung <i>mittendrin</i> finden Sie <a href="https://www.lbv.li/mittendrin.php" rel="external" target="_blank">beim Liechtensteiner Behinderten-Verband</a>.
      </p>
    </LayoutWithImage>

  </Layout>
)

export default ZeitungMittendrinPage
